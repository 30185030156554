<script>
  import CardTable from "@/components/cardTable/CardTable.vue";
  const Swal = require("sweetalert2");
  export default {
    components: {
      CardTable
    },
    props: {
      liquidacionId: {
        type: String,
        required: true
      }
    },
    async mounted() {
      const responseLiquidacion = await this.$store.getters.fetchGet({ path: `LiquidadorNomina/GetLiquidacion/${this.liquidacionId}` })
      const liquidacionGeneral = await responseLiquidacion.json()
      this.liquidacionExistente = !!liquidacionGeneral.idSeguimientoAportesEnLinea


      this.waiting = true;
      this.$store.getters.fetchGet({
        path: `LiquidadorNomina/GetDataLiquidacion/${this.liquidacionId}`
      })
        .then(response => response.json())
        .then(({ liquidacion, resumenTrabajadores, empresa }) => {
          this.liquidacion = liquidacion

          this.resumenTrabajadores = resumenTrabajadores
          this.numeroDeErrores = resumenTrabajadores.filter(trabajador => trabajador.error).length
          this.numeroDeAciertos = resumenTrabajadores.filter(trabajador => !trabajador.error).length
          this.empresa = empresa
        })
        .finally(() => {
          this.waiting = false;
        });


      window.$("#table_liquidador").DataTable();
    },
    data() {
      return {
        waiting: false,
        liquidacion: null,
        resumenTrabajadores: [],
        numeroDeErrores: null,
        numeroDeAciertos: null,
        waitingAportes: false,
        empresa: {
          id: "",
          userAportesEnLinea: "",
          passwordAportesEnLinea: "",
        },
        liquidacionExistente: false
      }
    },
    computed: {
      resumenTrabajadoresToCard() {
        return [
          {
            title: "Numero de trabajadores listos para liquidar",
            value: this.numeroDeAciertos,
            iconName: "circle-check",
            color: "#16a275",
            tipo: "squared",
            animated: "bounce",
          },
          {
            title: "Numero de trabajadores que les falta información",
            value: this.numeroDeErrores,
            iconName: "ban",
            color: "#f00",
            tipo: "squared",
            animated: "bounce",
          }
        ]
      },
      disableButtonLiquidacion() {
        return this.numeroDeErrores > 0 || this.numeroDeAciertos == null
      },
    },
    methods: {
      async getTokenAportes(APIAportes) {
        if (this.empresa.userAportesEnLinea) {
          //Revisa el local storage si existe tkAportesEnLinea
          const tkAportesEnLinea = JSON.parse(localStorage.getItem('tkAportesEnLinea'))
          //Si existe y no ha pasado 1 hora desde que se generó el token, lo usa
          if (tkAportesEnLinea && (new Date() - new Date(tkAportesEnLinea.fecha)) < 3600000) {
            return tkAportesEnLinea.token
          } else {
            const body = {
              user: this.empresa.userAportesEnLinea,
              password: this.empresa.passwordAportesEnLinea,
              ambiente: 2
            }
            console.log(body)
            const response = await fetch(`${APIAportes}/NominaElectronica/Autenticacion`, {
              method: 'POST',
              headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(body)
            })

            const { token } = await response.json()
            if (token) {
              localStorage.setItem("tkAportesEnLinea", JSON.stringify({ token, fecha: new Date() }));
            } else {
              throw new Error("No se pudo obtener el token");
            }
            return token;
          }
        }
      },
      enviarNominaIndividual() {
        this.liquidacion.informacionGeneral.tipoXML = "102";
        this.enviarDatosAportesEnLinea();
      },
      enviarNominaDeAjuste() {
        this.liquidacion.informacionGeneral.tipoXML = "103";
        this.enviarDatosAportesEnLinea();
      },

      async enviarDatosAportesEnLinea() {
        if (this.disableButtonLiquidacio || this.liquidacionExistente) return;

        const APIAportes = `https://nominaelectronicaapipruebas.aportesenlinea.com/Api`

        this.waitingAportes = true
        const token = await this.getTokenAportes(APIAportes)

        console.log(this.liquidacion);
        const responseLiquidacion = await fetch(`${APIAportes}/NominaElectronica/ProcesarNomina`, {
          method: 'POST',
          headers: {
            'accept': 'application/json',
            'token': token,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(this.liquidacion)
        })
        const resultLiquidacion = await responseLiquidacion.json();


        try {
          if (resultLiquidacion.successful && resultLiquidacion.idTrack) {
            const response = await this.$store.getters.fetchPost({ path: `LiquidadorNomina/liquidacion/${this.liquidacionId}/GuardarIdDeSeguimiento/${resultLiquidacion.idTrack}` })

            if (response.ok) {
              Swal.fire({
                title: 'Procesando nómina',
                text: resultLiquidacion.descripcion.join('\n'),
                icon: 'success',
                confirmButtonText: 'Ok'
              })
                .finally(() => {
                  this.$router.push({ path: `/helex/nomina_editar/${this.liquidacionId}` })
                })

            } else {
              throw new Error('Error al guardar el id de seguimiento')
            }

          } else {
            throw new Error(resultLiquidacion.descripcion.join('\n'))
          }
        } catch (error) {
          Swal.fire({
            title: 'Error',
            text: error.message,
            icon: 'error',
            confirmButtonText: 'Ok'
          })
        }
        this.waitingAportes = false
      },
      mapError(erroresString) {
        return erroresString.replace("Errores: ", "").split(", ").map(error => error.trim());
      }
    },
  }
</script>

<template>
  <div class="container-fluid ">
    <b-card class="">
      <h3>
        Liquidador de Nómina
      </h3>
      <div>
        <CardTable class="px-3 py-2" :data="resumenTrabajadoresToCard" />

        <div class="my-2" v-if="!liquidacionExistente">
          <div class="d-flex">
            <vs-button @click="enviarNominaIndividual" :disabled="disableButtonLiquidacion">
              <span v-if="!waitingAportes"> Liquidar nomina </span>
              <i v-else class="fa fa-spinner fa-spin"></i>
            </vs-button>
          </div>

          <p v-if="disableButtonLiquidacion">Todos los trabajadores previamente seleccionados deben tener la información
            actualizada</p>
        </div>
        <div v-else>
          <p>Esta nómina ya fue liquidada</p>
        </div>

        <table id="table_liquidador" class="table table-bordered table-responsive-md table-striped text-center">
          <thead>
            <tr>
              <th>Nombres</th>
              <th>Identificación</th>
              <th>Listo para liquidar</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="trabajador in resumenTrabajadores" :key="trabajador.id">
              <td class="text-center">{{ trabajador.nombres + trabajador.apellidos }}</td>
              <td class="text-center">{{ trabajador.numeroDocumento }}</td>
              <td class="text-center">
                <template v-if="!trabajador.error">Todo en orden</template>

                <ul class="p-0">
                  <li v-for="(error, index) in mapError(trabajador.error)" :key="index">{{ error }}</li>
                </ul>
              </td>
            </tr>
            <tr v-if="waiting">
              <td>
                <icon class="fa fa-spinner fa-spin" />
              </td>
              <td>
                <icon class="fa fa-spinner fa-spin" />
              </td>
              <td>
                <icon class="fa fa-spinner fa-spin" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- <pre class="text-white">{{ liquidacion }}</pre> -->
    </b-card>
  </div>
</template>

<style scoped>
  .wrapper-input {
    display: flex;
    flex-direction: column;
    margin-bottom: .5rem;
    margin: 1rem 0 0;
  }
</style>